import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { NextUIProvider } from "@nextui-org/react";
import { BrowserRouter } from 'react-router-dom';
import { SocketProvider } from './contexts/SocketContext';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <BrowserRouter>
    <NextUIProvider>
    <SocketProvider>
    <AuthProvider>
      <App />
      <Toaster />
    </AuthProvider>
    </SocketProvider>
    </NextUIProvider>
    </BrowserRouter>
  </React.StrictMode>
);

