import { useEffect, useState } from "react";
import { useSocket } from "../../contexts/SocketContext";
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { Pagination, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { IoIosArrowDown } from "react-icons/io";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { sqlDateToJsDate } from "../../tools/tools";


const TablaContactos = () => {
    const socket = useSocket();
    const [contacts, setContacts] = useState([]);
    const [totalContactos, setTotalContactos] = useState(0);
    const [paginas, setPaginas] = useState(0);
    const { logout } = useAuth();
    const [selectedKeys, setSelectedKeys] = useState(new Set(["20"]));
    const [selectedUsers, setSelectedUsers] = useState(new Set());

    useEffect(() => {
        // Llamar a getContacts
        getContacts(1);
        // eslint-disable-next-line
    }, [selectedKeys]);

    useEffect(() => {
        socket.off('contactsList');

        socket.on('contactsList', (response) => {
            if (response.code === 401) {
                toast.error(response.message);
                logout();
            }
            if (response.code === 200) {
                setContacts(response.contacts);
                setPaginas(response.paginas);
                setTotalContactos(response.totalContactos);
            }
        });

        return () => {
            socket.off('contactsList');
        };
        // eslint-disable-next-line
    }, [contacts]);

    const handleChange = (pagina) => {
        getContacts(pagina);
    }

    const handleSelectionChange = (selected) => {
        setSelectedUsers(selected);
    }

    const getContacts = (pagina) => {
        // Asegúrate de usar el valor actualizado de 'limit' aquí
        const limit = Array.from(selectedKeys)[0];
        socket.emit('getContacts', localStorage.getItem('token-autocall-ig'), pagina, limit);
    }

    const selectedValue = Array.from(selectedKeys).join(", ");

    return (
        <>
            <div className="flex justify-between items-center p-1 mb-1 mt-3">
                <p className="text-xs text-gray-300">Total contactos <span className="ml-1 text-gray-500">{totalContactos}</span></p>

                <div>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button className="capitalize bg-white text-gray-500 text-xs">
                                {selectedValue} por página <IoIosArrowDown />
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            variant="flat"
                            disallowEmptySelection
                            selectionMode="single"
                            selectedKeys={selectedKeys}
                            onSelectionChange={setSelectedKeys}
                        >
                            <DropdownItem key="20">20</DropdownItem>
                            <DropdownItem key="50">50</DropdownItem>
                            <DropdownItem key="100">100</DropdownItem>
                            <DropdownItem key="200">200</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <Table
                selectionMode="multiple"
                color="primary"
                selectedKeys={selectedUsers}
                onSelectionChange={handleSelectionChange}

            >
                <TableHeader>
                    <TableColumn>Nombre</TableColumn>
                    <TableColumn>Telefono</TableColumn>
                    <TableColumn>NIF</TableColumn>
                    <TableColumn>Provincia</TableColumn>
                    <TableColumn>Subida</TableColumn>
                </TableHeader>
                <TableBody>

                    {contacts.map((contact, index) => (
                        <TableRow key={index}>
                            <TableCell><p className="text-xs">{contact.nombre}</p></TableCell>
                            <TableCell><p className="text-xs">{contact.telefono}</p></TableCell>
                            <TableCell><p className="text-xs">{contact.nif}</p></TableCell>
                            <TableCell><p className="text-xs">{contact.provincia}</p></TableCell>
                            <TableCell><p className="text-xs">{sqlDateToJsDate(contact.created).toLocaleDateString()}</p></TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
            {
                selectedUsers.size > 0 && <p className="text-xs text-gray-300 mt-2">Contactos seleccionados: {selectedUsers.size}</p>
            }
            {
                paginas > 1 && <Pagination showControls total={paginas} initialPage={1} className="mt-4 justify-center flex" onChange={(page) => handleChange(page)} />
            }


        </>
    );
}

export default TablaContactos;
