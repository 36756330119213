import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { useSocket } from "../../contexts/SocketContext";

const StatusNum = () => {

    const socket = useSocket();
    const [response, setResponse] = useState('');



    useEffect(() => {
        socket.off('statusResponse');

        socket.emit('getStatus');

        socket.on('statusResponse', (response) => {
            setResponse(response);
            
        });

        return () => {
            socket.off('statusResponse');
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="mt-5">
            <Card className="p-3 w-full" shadow >
                <CardHeader className="w-full">
                    <div className="flex flex-col w-full">
                        <p className="text-gray-400 text-[13px]">Estado de las numeraciónes</p>
                    </div>

                </CardHeader>

                <CardBody>
           
                        {
                            response && Object.entries(response).map(([numero, data], index) => (
                                <p key={index} className="text-xs">{numero} - {data.status}</p>
                            ))
                        }
                 



                </CardBody>

            </Card>

        </div>
    );
}
export default StatusNum;