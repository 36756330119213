import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { Table, TableHeader, TableBody, TableColumn, TableRow, TableCell } from "@nextui-org/react";
import { Tooltip } from "@nextui-org/react";
import { MdDelete } from "react-icons/md";
import { MdModeEditOutline } from "react-icons/md";
import { TbLockOpen } from "react-icons/tb";
import { Chip } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { TbClockPlus } from "react-icons/tb";
import ModalCron from './ModalCron';
import { useState, useEffect } from 'react';
import { useSocket } from '../../contexts/SocketContext';
import { toast } from 'react-hot-toast';
import { frequenciaToText, horarioToText } from '../../tools/tools';
import { useAuth } from '../../contexts/AuthContext';
import ModalEditarCron from './ModalEditarCron';



const ListadoCrones = () => {
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [crones, setCrones] = useState([]);
    const socket = useSocket();
    const [cronToEdit, setCronToEdit] = useState(null);
    const { logout } = useAuth();
    const handleClose = () => {
        setAddNew(false);
    }
    const handlecloseEdit = () => {
        setEdit(false);
    }


    useEffect(() => {
        socket.off('getListaCrones');
        socket.off('cronResponse');
        //cronResponse

        socket.emit('getListaCrones', localStorage.getItem('token-autocall-ig'));

        socket.on('listaCronResponse', (response) => {
            if (response.code === 401) {
                toast.error(response.message);
                logout();
            }

            setCrones(response);

        });

        socket.on('cronResponse', (response) => {
            if (response.code === 401) {
                toast.error(response.message);
                logout();
            }
            if (response.code === 200) {
                toast.success(response.message);
                socket.emit('getListaCrones', localStorage.getItem('token-autocall-ig'));
                setAddNew(false);
            }
            if (response.code === 500) {
                toast.error(response.message);
            }
        });



        return () => {
            socket.off('getListaCrones');
            socket.off('cronResponse');
        }
    }
        // eslint-disable-next-line
        , [])

    return (
        <div className="mt-5">
            <Button auto className="mb-3" color="primary" shadow startContent={<TbClockPlus />} onClick={() => setAddNew(true)}>
                Agregar Cron
            </Button>

            <Card className="p-3 w-full" shadow >
                <CardHeader className="w-full">
                    <div className="flex flex-col w-full">
                        <p className="text-gray-400 text-[13px]">Listado de Crones</p>
                    </div>
                </CardHeader>
                <CardBody>

                    {
                        crones.length > 0 && (
                            <>
                                <Table>
                                    <TableHeader>
                                        <TableColumn>ID</TableColumn>
                                        <TableColumn>Descripción</TableColumn>
                                        <TableColumn>Frequencia</TableColumn>
                                        <TableColumn>Horario</TableColumn>
                                        <TableColumn>% nuevas llamadas</TableColumn>
                                        <TableColumn>% llamdas no contestadas</TableColumn>
                                        <TableColumn>% llamadas contestadas</TableColumn>
                                        <TableColumn>Activo</TableColumn>
                                        <TableColumn>Acciones</TableColumn>

                                    </TableHeader>
                                    <TableBody>

                                        {
                                            crones.map((cron, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{cron.id}</TableCell>
                                                    <TableCell>{cron.descripcion}</TableCell>
                                                    <TableCell>{frequenciaToText(cron.frecuencia)}</TableCell>
                                                    <TableCell>{horarioToText(cron.horario.toString())}</TableCell>
                                                    <TableCell>{cron.porcentaje_nuevas}%</TableCell>
                                                    <TableCell>{cron.porcentaje_nocontesta}%</TableCell>
                                                    <TableCell>{cron.porcentaje_contesta}%</TableCell>
                                                    <TableCell>
                                                        <Tooltip content="Cron activo">
                                                            <Chip color="success">Activo</Chip>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className="flex items-center gap-2">
                                                        <Tooltip content="Editar Cron">
                                                            <span className="text-lg text-default-400 cursor-pointer active:opacity-50"  onClick={() => { setEdit(true); setCronToEdit(cron.id) }}>
                                                                <MdModeEditOutline />
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip content="Desactivar Cron">
                                                            <span className="text-lg text-default-400 cursor-pointer active:opacity-50" >
                                                                <TbLockOpen />
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip content="Eliminar Cron" color='danger' >
                                                            <span className="text-lg text-danger cursor-pointer active:opacity-50 w-fit h-fit" onClick={() => {
                                                                socket.emit('deleteCron', { id: cron.id, token: localStorage.getItem('token-autocall-ig') });
                                                            }}>
                                                                <MdDelete />
                                                            </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))

                                        }



                                    </TableBody>
                                </Table>
                            </>

                        )
                    }


                </CardBody>
            </Card>
            <ModalCron open={addNew} close={handleClose} />
            { edit &&
                <ModalEditarCron open={edit} close={handlecloseEdit} id={cronToEdit} />
            }
        </div >

    );
}
export default ListadoCrones;   