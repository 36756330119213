import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import Test from "../componentes/configuracionllamadas/Test";
import StatusNum from "../componentes/configuracionllamadas/StatusNum";
import ListadoCrones from "../componentes/configuracionllamadas/ListadoCrones";
const ConfiguracionLlamadas = () => {
    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Configuración Llamadas</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Gestiona la configuración de las llamadas
            </h2>
            <p className="text-gray-400 text-[13px]">Gestiona las opciones relacionadas con el servicio</p>
            <div className="py-3 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="W-full">
                    <Test />
                </div>
                <div className="W-full">
                    <StatusNum />
                </div>
            </div>
            <div className="w-full">
                <ListadoCrones />
            </div>

        </section>
    );
}
export default ConfiguracionLlamadas;