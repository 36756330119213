import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { useMemo, useEffect, useState } from "react";
import { Chart } from 'react-charts';
import { useSocket } from "../../contexts/SocketContext";

const GraficoHoras = () => {
    const socket = useSocket();
    const [respondedCalls, setRespondedCalls] = useState([]);
    const [nonRespondedCalls, setNonRespondedCalls] = useState([]);

    useEffect(() => {
        socket.off('getInfoChartLlamadas');
        socket.emit('getInfoChartLlamadas', localStorage.getItem('token-autocall-ig'));

        socket.on('infoChartLlamadas', (data) => {
            const responded = [];
            const nonResponded = [];
            data.forEach(row => {
                const date = new Date(row.fecha);
                if (row.respuesta_llamada === 1) {
                    responded.push({ date, total: row.total });
                } else if (row.respuesta_llamada === 0) {
                    nonResponded.push({ date, total: row.total });
                }
            });

            setRespondedCalls(responded);
            setNonRespondedCalls(nonResponded);
        });

        return () => {
            socket.off('infoChartLlamadas');
        }
    }, [socket]);

    const data = useMemo(() => [
        {
            label: 'Llamadas respondidas',
            data: respondedCalls.map(item => ({
                date: item.date,
                stars: item.total,
            })),
        },
        {
            label: 'Llamadas no contestadas',
            data: nonRespondedCalls.map(item => ({
                date: item.date,
                stars: item.total,
            })),
        },
    ], [respondedCalls, nonRespondedCalls]);

    const primaryAxis = useMemo(
        () => ({
            getValue: datum => datum.date,
            scaleType: 'time',
            // Customizing the tick values to ensure each date appears only once per day
            tickFormat: d => d.toLocaleDateString('es-ES', { day: 'numeric', month: 'short' }),
        }),
        []
    );

    const secondaryAxes = useMemo(
        () => [
            {
                getValue: datum => datum.stars,
                scaleType: 'linear',
            },
        ],
        []
    );

    const getSeriesStyle = useMemo(
        () => series => ({
            color: series.index === 1 ? 'red' : undefined,
        }),
        []
    );

    return (
        <Card className="p-3 h-full min-h-96">
            <CardHeader className="flex flex-col items-start">
                <h2 className="text-xl">
                    Gráfico de llamdas 
                </h2>
                <p className="text-gray-400 text-[13px]">Información llamadas en los últimos 20 días</p>
            </CardHeader>
            <CardBody className="h-3/4">
                <Chart
                    className="w-full h-3/4"
                    options={{
                        data,
                        primaryAxis,
                        secondaryAxes,
                        getSeriesStyle,
                    }}
                />
            </CardBody>
        </Card>
    );
}
export default GraficoHoras;
