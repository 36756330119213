import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import TablaUsuarios from "../componentes/usuarios/TablaUsuarios";

const Usuarios = () => {
    return (
        <section>
        <Breadcrumbs isDisabled className="mb-6">
            <BreadcrumbItem>Administración</BreadcrumbItem>
            <BreadcrumbItem active>Usuarios</BreadcrumbItem>
        </Breadcrumbs>
        <h2 className="text-xl">
            Usuarios
        </h2>
        <p className="text-gray-400 text-[13px]">Gestiona los usuarios del sistema</p>
        <div className="py-3">
        <TablaUsuarios />
        </div>
    
    </section>
    );
    }
export default Usuarios;