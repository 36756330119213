
import { useSocket } from '../../contexts/SocketContext';
import { useState, useEffect } from 'react';
import ModalTemp from './modalTemp';
import { Spinner } from '@nextui-org/react';
import { Select, SelectItem } from "@nextui-org/select";
import toast from 'react-hot-toast';
const FileUploadComponent = () => {

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [empresa, setEmpresa] = useState(null);

    const socket = useSocket();


    useEffect(() => {
        if (socket) {
            socket.on('contactsTemp', (data) => {
                setResponse(data);
                setLoading(false);
            });
        }
        return () => {
            if (socket) {
                socket.off('contactsTemp');
            }
        };
    }
        , [socket]);


    const CHUNK_SIZE = 4000; // Tamaño del trozo en bytes

    const handleUpload = async (event) => {

        if(!empresa){
            toast.error('Primero debe seleccionar una empresa');
            return;
        }

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const base64 = btoa(
                    new Uint8Array(arrayBuffer)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                setLoading(true);

                // Divide el archivo en trozos y los envía uno por uno
                for (let i = 0; i < base64.length; i += CHUNK_SIZE) {
                    const chunk = base64.slice(i, i + CHUNK_SIZE);
                    socket.emit('uploadContacts', chunk, localStorage.getItem('token-autocall-ig'),empresa);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    }
    return (
        <>
            <Select
                label="Selecciona empresa"
                placeholder="Selecciona la empresa donde se subirán los contactos"
                className="w-full mb-2"
                onChange={(e) => setEmpresa(e.target.value)}
            >
                <SelectItem key="integram">Integram</SelectItem>
                <SelectItem key="jqs">JQS</SelectItem>
                <SelectItem key="kram">Kram</SelectItem>
            </Select>

            <div className="flex items-center justify-center w-full">

                <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">Haz clic aquí</span> o arrastra y suelta un archivo
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">Excel o CSV</p>
                    </div>
                    <input id="dropzone-file" type="file" className="hidden" onChange={handleUpload} accept=".xlsx, .xls" />
                </label>

            </div>


            {loading && (
                <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-100 bg-opacity-50 ">
                    <Spinner label="Loading..." color="warning" />
                </div>
            )}
            <ModalTemp json={response} />
        </>
    );
};

export default FileUploadComponent;