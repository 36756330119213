
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";
import { useSocket } from "../../contexts/SocketContext";
import { Input, Select, SelectItem } from "@nextui-org/react";
import { useState } from "react";
import toast from 'react-hot-toast';




const EditUser = ({ id, open, close }) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const socket = useSocket();
    const [user, setUser] = useState({
        username: '',
        name: '',
        lastname: '',
        password: '',
        company: ''
    });
    const EMPRESAS = [
        { value: '1', label: 'Gaman Droom' },
        { value: '2', label: 'Integram Consilis' },
        { value: '3', label: 'JQS Consultores' },
        { value: '4', label: 'Kram Assesores' },
    ];
    useEffect(() => {
        if (open) {
            const empresa = EMPRESAS.find(e => e.label === open.empresa);
            const EmpresaArrayPosition = EMPRESAS.indexOf(empresa);
            
     
            setUser({ username: open.username, name: open.nombre, lastname: open.apellido, password: "", company: EmpresaArrayPosition, defaultSelectedKeys: EmpresaArrayPosition });
            onOpen();
        }
    }
        // eslint-disable-next-line
        , [open]);

    const handleClose = () => {
        onClose();
        close();
        setUser({ username: '', name: '', lastname: '', password: '', company: '' });
    }
    const handleGuardar = () => {
        if (user.username === '' || user.name === '' || user.lastname === '' ||  user.company === '') {
            toast.error('Rellean los campos obligatorios');
            return;
          }
         
          const empresa = EMPRESAS[user.company].label;
          user.company = empresa;
          

        socket.emit('editUser', user, localStorage.getItem('token-autocall-ig'));

        setUser({ username: '', name: '', lastname: '', password: '', company: '' });
        onClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Editando el usuario {user.username}</ModalHeader>
                            <ModalBody>

                                <Input size="sm" type="text" label="Nombre de usuario" onChange={(e) => setUser({ ...user, username: e.target.value })} value={user.username} disabled />
                                <Input size="sm" type="text" label="Nombre" onChange={(e) => setUser({ ...user, name: e.target.value })} value={user.name} />
                                <Input size="sm" type="text" label="Apellido" onChange={(e) => setUser({ ...user, lastname: e.target.value })} value={user.lastname} />
                                <Input size="sm" type="text" label="Contraseña" onChange={(e) => setUser({ ...user, password: e.target.value })} value={user.password} />
                                <span className="text-xs text-gray-400 mb-2 ml-2">Dejar en blanco si no se desea modificar la contraseña</span>
                                <Select
                                    size="sm"
                                    label="Empresa"
                                    onChange={(e) => setUser({ ...user, company: e.target.value })}
                                    value={user.company}
                                    defaultSelectedKeys={[user.defaultSelectedKeys.toString()]}
                                >
                                    {EMPRESAS.map((empresa, index) => (
                                        <SelectItem key={index} value={empresa.value}>
                                            {empresa.label}
                                        </SelectItem>

                                    ))}
                                </Select>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={handleClose}>
                                    Cerrar
                                </Button>
                                <Button color="primary" onPress={handleGuardar} >
                                    Modificar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
export default EditUser;
