import { User } from "@nextui-org/react";

const Userio = () => {
    const user = JSON.parse(localStorage.getItem('user-autocall-ig'));
   const name = user ? `Bienvenido de nuevo, ${user.nombre} ${user.apellido}  👋` : 'Bienvenido 👋';

    return (
        <>
            <User
            className="p-3"
                name={name}
                description={user ? user.empresa : ''}
                avatarProps={{
                    src: user ? user.avatar : '',
                    name: user ? user.nombre : "",
                }}
            />
        </>
    );
}
export default Userio;