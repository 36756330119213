import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import TablaContactos from "../componentes/listadocontactos/TablaContactos";

const ListadoContactos = () => {
    return (
        <section>
        <Breadcrumbs isDisabled className="mb-6">
            <BreadcrumbItem>Principal</BreadcrumbItem>
            <BreadcrumbItem active>Listar Contactos</BreadcrumbItem>
        </Breadcrumbs>
        <h2 className="text-xl">
            Listar Contactos
        </h2>
        <p className="text-gray-400 text-[13px]">Listado de contactos de la base de datos</p>
        <div className="py-3">
        <TablaContactos />
        </div>
    
    </section>
    );
    }
export default ListadoContactos;