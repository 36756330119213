import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Select, SelectItem, Slider } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import toast from 'react-hot-toast';


const ModalEnviarEmpresas = ({ openModal, close, empresa }) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [horario, setHorario] = useState(null);
    const socket = useSocket();
    const [toastId, setToastId] = useState(null);
    const [totalContactos, setTotalContactos] = useState(0);
    const [contactosEnviar, setContactosEnviar] = useState(0);
    useEffect(() => {
        if (openModal) {
            onOpen();
        }
    }
    // eslint-disable-next-line
        , [openModal]);



    useEffect(() => {
        socket.off('getCantidadContactosParaEnviar');
        
        socket.on('getCantidadContactosParaEnviarResponse', (cantidad) => {
            setTotalContactos(cantidad);
        }
        );


        return () => {
            socket.off('getCantidadContactosParaEnviar');
        }

        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        socket.off('contactsSendend');
    
    
        const handleContactsSendend = (message) => {
            
          toast((t) => (
            <div>
              <p className="font-medium ">Resultados del envio de contactos</p>
              <p className="text-sm text-gray-600">Contactos añadidos correctamente: {message.contactos_anadidos}</p>
                <p className="text-sm text-gray-600">Contactos repetidos o erroneos: {message.contactos_existentes}</p>
               </div>
    
          ), {
            duration: 4000,
            style: {
              width: 'auto',
              color: '#000',
            }
          });
    
          if (toastId) {
            toast.dismiss(toastId);
            setToastId(null);
          }
        };
    
    
        socket.on('contactsSendend', handleContactsSendend);
    
        return () => {
          socket.off('contactsSendend', handleContactsSendend);
        };
    
        // eslint-disable-next-line
      }, [toastId]);
    

    const handleClose = () => {
        onClose();
        close();
        setHorario(null);
        setContactosEnviar(0);
        setTotalContactos(0);


    };

    const handleEnviar = () => {
        socket.emit('enviarContactosPanel', localStorage.getItem('token-autocall-ig'), empresa, contactosEnviar);
        const id = toast.loading('Enviando contactos, por favor espere...');
        setToastId(id);
        handleClose();
    }
    const changeHorario = (value) => {
        socket.emit('getCantidadContactosParaEnviar', localStorage.getItem('token-autocall-ig'), empresa, value);
        setHorario(value);

    }




    return (
        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Enviar Contactos a {empresa === 1 ? "Integram" : empresa === 2 ? "JQS" : "Kram"}</ModalHeader>
                            <ModalBody>
                                <p className="text-gray-400 text-[13px]">Selecciona el horario para enviar los contactos</p>
                                <Select placeholder="Seleccionar horario" className="w-full mb-4" onChange={(e) => changeHorario(e.target.value)}>
                                    <SelectItem value="Mañana" key="Mañana">Mañana</SelectItem>
                                    <SelectItem value="Tarde" key="Tarde">Tarde</SelectItem>
                                    <SelectItem value="Todo" key="Todo" >Todo el día</SelectItem>
                                </Select>
                                {
                                    horario && (
                                        <>
                                            <p className="text-gray-400 text-[13px]">Selecciona la cantidad de contactos a enviar{totalContactos > 0 && ` (${totalContactos} disponibles)`}</p>

                                        </>
                                    )
                                }
                                {totalContactos > 0 && (
                                    <Slider className="mt-3"
                                        label="Cantidad de contactos a descargar"
                                        value={contactosEnviar}
                                        onChange={(val) => setContactosEnviar(val)}
                                        step={1}
                                        maxValue={totalContactos}
                                        minValue={0}
                                    />
                                )

                                }
                                {
                                    contactosEnviar > 0 && (
                                        <div className="mt-3 text-xs p-2">
                                            <p className="text-gray-400 text-[13px]">Total de contactos a enviar: {contactosEnviar}</p>
                                        </div>
                                    )
                                }

                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button color="primary" onPress={handleEnviar}>
                                    Enviar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}

export default ModalEnviarEmpresas;