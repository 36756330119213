import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { FaPause } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { Code } from "@nextui-org/react";
import { useEffect } from "react";
import { useState } from "react";
import { useSocket } from "../../contexts/SocketContext";
import { Tooltip } from "@nextui-org/react";
import { toast } from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';

const Test = () => {

    const socket = useSocket();
    const { logout } = useAuth();
    const [logs, setLogs] = useState([]);
    const [llamando, setLlamando] = useState(false);

    useEffect(() => {
        socket.off('logs');
        socket.off('confLlamadas');
        socket.off('changeCallService');


        socket.emit('confLlamadas', localStorage.getItem('token-autocall-ig'));

        socket.on('confLlamadas', (response) => {
            if (response.code === 401) {
                toast.error(response.message);
                logout();
            }
            if (response.code === 200) {
                setLlamando(response.response);

            }

            // setLlamando(data);
        });

        socket.on('changeCallService', (response) => {
            if (response.code === 401) {
                toast.error(response.message);
                logout();
            }
            console.log('response', response);
            setLlamando(response.message);
        }
        );



        socket.on('logs', (data) => {
            console.log(data);
            //antes de añadir una nueva linea de log, si esta es mas grande que 10, eliminar la primera
            setLogs((currentLogs) => {
                let newLogs = [...currentLogs, data];
                if (newLogs.length > 10) {
                    newLogs.shift(); // Elimina el primer elemento
                }
                return newLogs; // Devuelve los nuevos logs
            });
        });
        return () => {
            socket.off('logs');
            socket.off('confLlamadas');
            socket.off('changeCallService');
        }
    }
        , []);


    return (
        <div className="mt-5">
            <Card className="p-3" shadow >
                <CardHeader className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-xl">
                            Estado del servicio
                        </h2>
                        <p className="text-gray-400 text-[13px]">Información sobre el estado del servicio</p>
                    </div>
                    <div>
                        <Tooltip content={llamando === 0 ? "Iniciar llamadas" : "Detener llamadas"} position="top">
                            <Button color="primary" auto
                                onClick={() => socket.emit('changeCallService', localStorage.getItem('token-autocall-ig'))}
                            >

                                {
                                    llamando === 0 ? (
                                        <FaPlay className="color-white" />
                                    ) : (
                                        <FaPause className="color-white" />
                                    )
                                }

                            </Button>
                        </Tooltip>
                    </div>
                </CardHeader>

                <CardBody>
                    <Code className="p-3" shadow>


                        {logs <= 0 ? (
                            <p>Aquí se mostrarán los logs del servicio</p>
                        ) : (
                            logs.map((log, index) => (
                                <p key={index}>{log}</p>
                            ))
                        )}
                    </Code>

                </CardBody>

            </Card>

        </div>
    );
}
export default Test;