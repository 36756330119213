import { Card, CardHeader, CardBody, CardFooter, Divider, Link, Image, Input, Button } from "@nextui-org/react";
import React, { useState, useEffect } from 'react';
import logoMin from '../assets/logoInteg.png';
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { useSocket } from '../contexts/SocketContext';
import  toast  from 'react-hot-toast';
import { useAuth } from '../contexts/AuthContext';

const LoginForm = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const socket = useSocket();
    const { login, userInfo } = useAuth();

    const toggleVisibility = () => setIsVisible(!isVisible);

    const handleLogin = async () => {

        socket.emit('login', { username: username, password: password });

    };

    useEffect(() => {
        // Eliminar el oyente del evento antes de añadir uno nuevo
        socket.off('loginResponse');

        // Escuchar el evento de respuesta 'loginResponse' del servidor
        socket.on('loginResponse', (response) => {
            if(response.code !== 200){
                toast.error(response.message);
            }
        

            if(response.code === 200){
                toast.success('Login correcto');
                login(response.token);
                userInfo(response.user);

            }

        });

        // Devolver una función de limpieza que se ejecutará cuando el componente se desmonte
        return () => {
            socket.off('loginResponse');
        };
        // eslint-disable-next-line
    }, []);


    return (
        <div>
            <Card className="max-w-[400px] min-w-[300px]" >
                <CardHeader className="flex gap-3">
                    <Image
                        alt="nextui logo"
                        height={25}
                        radius="sm"
                        width={25}
                        src={logoMin}
                    />
                    <div className="flex flex-col">
                        <p className="text-md">Automarcador</p>
                        <p className="text-small text-default-500">Integram Group</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody className="flex" >
                    <Input className="mb-3" label="Nombre de usuario" placeholder="Introduce tu nombre de usuario" onChange={(e) => setUsername(e.target.value)} value={username} />
                    <Input
                        label="Contraseña"
                        type={isVisible ? "text" : "password"}
                        placeholder="Tu contraseña"
                        onChange={(e) => setPassword(e.target.value)} value={password}
                        endContent={
                            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                {isVisible ? (
                                    <FaRegEyeSlash className="text-2xl text-default-400 pointer-events-none" />
                                ) : (
                                    <FaRegEye className="text-2xl text-default-400 pointer-events-none" />
                                )}
                            </button>

                        }

                        className="mb-6"
                    />
                    <Button color="primary" onClick={handleLogin}>Iniciar sesión</Button>
                </CardBody>
                <Divider />
                <CardFooter >
                    <Link
                        isExternal
                        showAnchorIcon
                        className="text-sm text-center"

                    >
                        Notificar una incidencia
                    </Link>
                </CardFooter>
            </Card>

        </div>
    );
};

export default LoginForm;