import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Select, SelectItem, Slider, Input } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import { toast } from 'react-hot-toast';

const ModalCron = ({ open, close }) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [recurrente, setRecurrente] = useState(false);
    const [frequency, setFrequency] = useState(0);
    const [horario, setHorario] = useState(0);

    const [callNew, setCallNew] = useState(0);
    const [callUnanswered, setCallUnanswered] = useState(0);
    const [callAnswered, setCallAnswered] = useState(0);
    const [description, setDescription] = useState('');
    const socket = useSocket();

    const handleSliderChange = (type, value) => {



        if (type === "nuevas") {
            const remaining = 100 - callUnanswered - callAnswered;
            setCallNew(value > remaining ? remaining : value);
        } else if (type === "noContestadas") {
            const remaining = 100 - callNew - callAnswered;
            setCallUnanswered(value > remaining ? remaining : value);
        } else if (type === "contestadas") {
            const remaining = 100 - callNew - callUnanswered;
            setCallAnswered(value > remaining ? remaining : value);
        }


    };
    const handleClose = () => {
        onClose();
        close();
        //resetear los valores
        setRecurrente(false);
        setFrequency(0);
        setHorario(0);
        setCallNew(0);
        setCallUnanswered(0);
        setCallAnswered(0);
        setDescription('');
        
    }

    const handleGuardar = () => {
        // Primero validamos que los campos esten llenos y correctos


        if (recurrente && frequency.length > 0 && horario.length > 0 && callNew > 0 && callUnanswered > 0 && callAnswered > 0) {

            let data = {
                recurrente,
                frequency,
                horario,
                callNew,
                callUnanswered,
                callAnswered,
                description
            }
            socket.emit('createCron', data, localStorage.getItem('token-autocall-ig'));
            handleClose();
        }else{
            toast.error('Debes rellenar todos los campos');
        }



    }





    useEffect(() => {
        if (open) {
            onOpen();
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose} onOpenChange={onOpenChange}>
                <ModalContent>
                    <>
                        <ModalHeader className="flex flex-col gap-1">Añadir nuevo cron</ModalHeader>
                        <ModalBody>
                            <Input
                                label="Descripción del cron"
                                onChange={(e) => setDescription(e.target.value)}

                            />

                            <Select
                                label="Es un cron recurrente? "
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    if (e.target.value === '1') {
                                        setRecurrente(true);
                                    } else {
                                        setRecurrente(false);
                                    }

                                }}
                            >
                                <SelectItem key="1" value="1">Si</SelectItem>
                                <SelectItem key="2" value="2">No</SelectItem>
                            </Select>
                            {
                                recurrente && (
                                    <Select
                                        label="Que dias de la semana se ejecutara el cron"
                                        selectionMode="multiple"
                                        onChange={(e) => {
                                            setFrequency(e.target.value)
                                        }
                                        }

                                    >
                                        <SelectItem key="1" value="1">Lunes</SelectItem>
                                        <SelectItem key="2" value="2">Martes</SelectItem>
                                        <SelectItem key="3" value="3">Miercoles</SelectItem>
                                        <SelectItem key="4" value="4">Jueves</SelectItem>
                                        <SelectItem key="5" value="5">Viernes</SelectItem>
                                        <SelectItem key="6" value="6">Sabado</SelectItem>
                                        <SelectItem key="7" value="7">Domingo</SelectItem>
                                    </Select>
                                )
                            }
                            {
                                recurrente && frequency.length > 0 && (
                                    <Select
                                        label="En que horario se ejecutara el cron"
                                        selectionMode="multiple"
                                        onChange={(e) => {
                                            setHorario(e.target.value)
                                        }
                                        }
                                    >
                                        <SelectItem key="1" value="1">Horario de oficina de mañana</SelectItem>
                                        <SelectItem key="2" value="2">Horario de oficina de tarde</SelectItem>

                                    </Select>

                                )
                            }

                            {
                                recurrente && frequency.length > 0 && horario.length > 0 && (
                                    <>
                                        <span className="text-gray-400 text-[13px] mt-5">Gestión del Cron</span>
                                        <Slider label="% Llamadas nuevas" value={callNew} onChange={(val) => handleSliderChange('nuevas', val)} />
                                        <Slider label="% Llamadas no contestadas" value={callUnanswered} onChange={(val) => handleSliderChange('noContestadas', val)} />
                                        <Slider label="% Llamadas contestadas" value={callAnswered} onChange={(val) => handleSliderChange('contestadas', val)} />
                                    </>
                                )}

                        </ModalBody>
                        <ModalFooter> 
                            <Button color="danger" variant="light" onPress={handleClose}>
                                Cerrar
                            </Button>
                            <Button color="primary" onPress={handleGuardar} >
                                Guardar
                            </Button>
                        </ModalFooter>
                    </>
                </ModalContent>
            </Modal>
        </>
    )
}
export default ModalCron