import { Button, Card, CardBody, CardHeader, Input } from "@nextui-org/react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect } from "react";


const Asesor = () => {
    const [pregunta, setPregunta] = useState('');
    const [respuesta, setRespuesta] = useState('');
    const [loading, setLoading] = useState(false);
    const socket = useSocket();


    useEffect(() => {

        socket.off('respuestaIA');


        socket.on('respuestaIA', (data) => {
            setLoading(false);
           setRespuesta(data.message);
        });

        return () => {
            socket.off('respuestaIA');
        };
    }, []);

    const handleSendPregunta = () => {
        if (!pregunta) {
            toast.error('Debes escribir una pregunta');
            return;
        }
        setLoading(true);
        socket.emit('preguntaIA',pregunta, localStorage.getItem('token-autocall-ig'));

    }


    return (
        <Card className="p-3 mt-4" shadow >
            <CardHeader className="flex items-center justify-between">
                <div className="flex flex-col">
                    <h2 className="text-xl">
                        Asesor personalizado
                    </h2>
                    <p className="text-gray-400 text-[13px]">Haz preguntas sobre el servicio directamente a la IA ✨</p>
                </div>

            </CardHeader>

            <CardBody>
                <div className="flex justify-between items-center">
                    <Input className="mb-4" placeholder="Que porcentaje de llamdas se realizó ayer" label="Escribe tu pregunta" onChange={(e) => setPregunta(e.target.value)} />
                    <Button
                        auto
                        color="primary"
                        onClick={handleSendPregunta}
                    >Enviar</Button>
                </div>  
                <div className="flex justify-between items-center bg-gray-100 p-3 rounded-lg">
                    <p className="text-xs">
                    {loading ? 'Cargando...' : respuesta}
                    </p>
                </div>

            </CardBody>

        </Card>

    );
}
export default Asesor;