import { useEffect, useState } from "react";
import { useSocket } from "../../contexts/SocketContext";
import { Switch } from "@nextui-org/react";
import toast from "react-hot-toast";


const BloqueoEmpresaLlamdas = () => {
    const socket = useSocket();
    const [empresaBloqueada, setEmpresaBloqueada] = useState(null);

    const handleChange = (empresa) => async () => {

        if(empresaBloqueada !== empresa){
            setEmpresaBloqueada(empresa);
            socket.emit('changeBloqueoEmpresaLlamadas', empresa,localStorage.getItem('token-autocall-ig'));

        }else{
            setEmpresaBloqueada(null);
            socket.emit('changeBloqueoEmpresaLlamadas',  0, localStorage.getItem('token-autocall-ig'));
        }
        
       
    }


    useEffect(() => {
        socket.off('changeBloqueoEmpresaLlamadasResponse');
        socket.off('bloqueoEmpresaLlamadasResponse');

        socket.emit('getBloqueoEmpresaLlamadas', localStorage.getItem('token-autocall-ig'));

        socket.on('bloqueoEmpresaLlamadasResponse', (response) => {
            setEmpresaBloqueada(response.valor);
        });

        socket.on('changeBloqueoEmpresaLlamadasResponse', (response) => {
            let mensaje = response.valor === 0 ? 'No hay empresas bloqueadas' : response.valor === 1 ? 'Se han bloqueado las llamdas a IG' : response.valor === 2 ? 'Se han bloqueado las llamdas a JQS' : 'Se han bloqueado las llamdas a KRAM';
            mensaje += " para el próximo día";
            toast.success(mensaje);
        });

        return () => {
            socket.off('bloqueoEmpresaLlamadasResponse');
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col gap-3">
            <p className="text-gray-500 text-sm">Empresas Bloqueadas para llamar</p>

            <Switch isSelected={empresaBloqueada === 1}  onChange={handleChange(1)}>
                IG
            </Switch>
            <Switch isSelected={empresaBloqueada === 2} onChange={handleChange(2)}>
                JQS
            </Switch>
            <Switch isSelected={empresaBloqueada === 3} onChange={handleChange(3)}>
                KRAM
            </Switch>
        </div>
    );
}
export default BloqueoEmpresaLlamdas;